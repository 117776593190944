//import logo from './logo.svg';
import './App.css';
import ResponsiveAppBar from './components/Nav';
import Home from './components/Home';
import Footer from './components/Footer';
import Training from './components/Training'
import Race from './components/Race'
import Login from './components/Login'
import Signup from './components/Signup';
import Activities from './components/Activities';
import ActivitiesBar from './components/ActivitiesBar';
import Administration from './components/Administration';
import {BrowserRouter as HashRouter, Routes, Route} from 'react-router-dom';

import theme from "./assets/css/theme"
import { ThemeProvider } from '@mui/material/styles';
import { ProtectedRoute } from './routes/protectedRoutes';

import AuthProvider from './provider/authProvider';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <AuthProvider>
          <div className="App">
            <Routes>
              <Route exact path="/" element={<><ResponsiveAppBar/><Home/></>}/>
              <Route path="/training" element={<><ResponsiveAppBar/><Training/></>} />
              <Route path="/RDS" element={<><ResponsiveAppBar/><Race/></>} />
              <Route path='/login' element={<Login/>}/>
              <Route path='/signup' element={<Signup/>}/>
              <Route path='/activities' element={<ProtectedRoute/>}>
                <Route index element={<><ActivitiesBar/><Activities/></>}/>
              </Route>

              <Route path='/administration' element={<ProtectedRoute/>}>
                <Route index element={<><ActivitiesBar/><Administration/></>}/>
              </Route>

            </Routes>
            <Footer />
          </div>
        </AuthProvider>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;