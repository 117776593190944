import { createTheme } from '@mui/material/styles';
import { green, blue } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: green[700],
      light: '#42a5f5',
      dark: '#1565c0',
      contrastText: '#fff',
    },
    secondary: {
      main: blue[500],
      light: green[50],
    },
    icon:{
      main: green[50],
      dark: green[800],
    },
    button:{
      main: green[50],
      dark: green[300],
      light: green[50],
      contrastText: green[700],
    }
  },
  typography: {
    fontFamily: 'Arial, sans-serif', // Default font family
    // Set the default font color to black
    body1: {
      color: 'black', // Change 'body1' or other text variants as needed
    },
  },
});
  
export default theme;