import React, { useEffect, useState } from 'react';
import { Container, Grid, TextField, Button, Link, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../provider/authProvider';

import Cookies from 'js-cookie';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [logoPath, setLogoPath] = useState('');
    const [loadingLogo, setLoadingLogo] = useState(true);
    const clubID = Cookies.get('club_id');
    const {setToken} = useAuth();



    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
          try {
            const response = await fetch(`https://tivity.fr:4000/api/getLogoPath/${clubID}`);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
      
            const data = await response.json();
      
            if (data && data.message && data.message.length > 0) {
              setLogoPath(data.message[0].LOGO);
              setLoadingLogo(false);
            } else {
              console.error('Data is empty or does not contain the expected structure.');
            }
          } catch (error) {
            console.error('Error:', error);
          }
        }
      
        fetchData();
      }, [clubID]);



    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://tivity.fr:4000/api/login', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email, password }),
            });
        
            if (response.ok) {
              const res = await response.json();

              const decodedToken = JSON.parse(atob(res.message.split('.')[1]));

              if(decodedToken.role !== null){
                localStorage.setItem('token', res.message);
                setToken(res.message);
                
                setError('');

                const navigatePromise = new Promise((resolve) => {
                  navigate('/activities');
                  resolve();
                });

                await navigatePromise;
              } else {
                setError("Validation par l'administrateur en cours");
              }
            } else {
              const errorData = await response.json();
              setError(errorData.message);
            }
          } catch (error) {
            setError('Une erreur s\'est produite. Veuillez réessayer.');
          }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Container maxWidth="sm" >
              <Button
                  variant="contained"
                  color='button'
                  style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      margin: '20px',
                  }}
                  onClick={() => {
                      window.history.back();
                  }}
              ><ArrowBackIcon/>
                  Retour
              </Button>
              <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} align="center">
                  {loadingLogo ? <CircularProgress /> :
                  <Link href="/">
                      <Box
                        component="img"
                        alt="Logo"
                        sx={{
                              marginWidth: '100%',
                              margin: '0 auto'
                        }}

                        src={logoPath}
                      />
                  </Link> 
                    }
                  </Grid>
                  <Grid item xs={12}>
                  <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                      <Grid item xs={12}>
                          <TextField
                          id="email"
                          type="email"
                          label="Email"
                          variant="outlined"
                          fullWidth
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          error={error !== ''}
                          helperText={error}
                          />
                      </Grid>
                      <Grid item xs={12}>
                          <TextField
                          id="password"
                          type="password"
                          label="Password"
                          variant="outlined"
                          fullWidth
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          error={error !== ''}
                          />
                      </Grid>
                      <Grid item xs={12}>
                          <Button
                          id="connect-btn"
                          variant="contained"
                          color="button"
                          fullWidth
                          type="submit"
                          >
                          Connexion
                          </Button>
                      </Grid>
                      <Grid item xs={12}>
                          <Link href="/signup" color="inherit">
                              <Button
                              id="inscription-btn"
                              variant="contained"
                              color="button"
                              fullWidth
                              >
                              Inscription
                              </Button>
                          </Link>
                      </Grid>
                      <Grid item xs={12}>
                          <Link href="#" variant="body2" style={{ color: 'black' }} underline='none'>
                              Mot de passe oublié ?
                          </Link>
                      </Grid>
                      </Grid>
                  </form>
                  </Grid>
              </Grid>
            </Container>
        </div>
    );
}

export default Login;
