import React, { useState } from 'react';
import { Container, Grid, TextField, Button, Link, Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import ReactPhoneInput from 'react-phone-input-material-ui';

import { useNavigate  } from 'react-router-dom';


import logo from '../assets/images/logo.png'
import Cookies from 'js-cookie';

function Signup() {
  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [sexe, setSexe] = useState('');
  const [birthday, setBirthday] = useState(dayjs(new Date()));
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const [error, setError] = useState('');

  const clubId = Cookies.get('club_id');

  const navigate = useNavigate ();

  function validateUserData(userData) {
    for (const key in userData) {
      if (!userData[key]) {
        // If any value is empty, return false
        return false;
      }
    }
    // If all values are not empty, return true
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the password and password check match
    if (password !== passwordCheck) {
      setError('Passwords do not match.');
      return;
    }

    const birthdayString = birthday.toISOString().slice(0, 19).replace('T', ' ');

    // Create an object with the user's data
    const userData = {
      clubId,
      name,
      firstName,
      email,
      phone,
      sexe,
      birthdayString,
      password,
    };

    if(validateUserData(userData)){
      try {
        const response = await fetch('https://tivity.fr:4000/api/signup', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userData),
        });
  
        if (response.ok) {
          // Reset the error state on successful registration
          setError('');
  
          navigate('/login');
  
          // Handle a successful registration response (e.g., redirect to a new page)
          // You can add your logic here
        } else {
          // Handle an unsuccessful registration response (e.g., set error state)
          const responseData = await response.json(); // Parse the response JSON
          setError(responseData.message || 'Registration failed. Please try again.');
        }
      } catch (error) {
        // Handle any network or other errors
        console.error('Error:', error);
      }
    }
    else{
      setError("Merci de remplir tous les champs");
    }
  };

  const updatePhone = (e) => {
      setPhone(e);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Container maxWidth="sm">
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} align="center">
            <Link href="/">
              <Box
                component="img"
                sx={{
                  maxWidth: '100%',
                  margin: '0 auto',
                }}
                alt="Logo"
                src={logo}
              />
            </Link>
          </Grid>
          <Grid item xs={12} align="center">
            <Typography variant="h5" color="error">
              {error}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="name"
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="firstName"
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="Sexe">Sexe</InputLabel>
                        <Select
                            label="Sexe"
                            id="Sexe"
                            value={sexe}
                            onChange={(e) => setSexe(e.target.value)}
                            >
                            <MenuItem value="Homme">Homme</MenuItem>
                            <MenuItem value="Femme">Femme</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Date de naissance"
                                format="DD-MM-YYYY"
                                value={birthday}
                                onChange={(newValue) => setBirthday(newValue)}
                            />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="email"
                    type="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={error !== ''}
                    helperText={error}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ReactPhoneInput
                    label='Mobile'
                    required
                    country={'fr'}
                    value={phone} 
                    onChange={updatePhone}
                    enableSearch
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="password"
                    type="password"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="password-check"
                    type="password"
                    label="Confirm Password"
                    variant="outlined"
                    fullWidth
                    required
                    value={passwordCheck}
                    onChange={(e) => setPasswordCheck(e.target.value)}
                    error={error !== ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    id="signup-btn"
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                  >
                    Je m'inscris
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Link href="/login" variant="body2">
                    Déjà un compte ? Connexion
                  </Link>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Signup;
