import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link } from '@mui/material';

import { useLocation } from 'react-router-dom';
import logo from "../assets/images/logo-white.png";

import Cookies from 'js-cookie'

const pages = ['Club', 'Entrainement', 'RDS'];

const pageNameToRoute = {
  Club: '/',
  Entrainement: '/training',  // Add more page names and corresponding routes here
  RDS : '/RDS',
};

function ResponsiveAppBar() {

  Cookies.set('club_id', '1', { expires: 7 }); // This must be set by the club owner on the webpage. The id is given by me 

  const [anchorElNav, setAnchorElNav] = useState(null);
  const location = useLocation(); // Get the current route
  const currentRoute = location.pathname; // Extract the current route path

  // Use currentRoute to determine the activePage
  const activePage = Object.keys(pageNameToRoute).find(
    (page) => pageNameToRoute[page] === currentRoute
  );

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handlePageChange = (page) => {
    const route = pageNameToRoute[page];

    if (route) {
      window.location.href = route;
    }

    handleCloseNavMenu();
  };

  return (
      <AppBar position="static" color="primary">
        <Container maxWidth="x1">
          <Toolbar disableGutters>            
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={() => handlePageChange(page)}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={() => handlePageChange(page)}
                  sx={{
                    my: 2,
                    color: activePage === page ? 'parent' : 'white',
                    display: 'block',
                    backgroundColor: activePage === page ? 'secondary.light' : '', // Change button color for the active page
                  }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
              <Link href="/">
                <Box
                  component="img"
                  sx={{ height: 54, margin: '0 auto' }} // Center the logo
                  alt="Logo"
                  src={logo}
                />
              </Link>
            </Box>
            
            <Button href="/login" component={Link} variant="contained" color='button'>
                Connexion
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
  );
}

export default ResponsiveAppBar;
