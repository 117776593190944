import React, {useState, useRef} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Input from "@mui/material/Input"

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import dayjs from 'dayjs';

const CreateActivity = ({userID, onClose}) => {
    const [estimatedTime, setEstimatedTime] = useState(null);
    const trainingImageInputRef = useRef(null);
    const [startTime, setStartTime] = useState(dayjs(new Date()));
    const [duration, setDuration] = useState(dayjs(new Date()));
    const [isSelectorTypeOpen, setisSelectorTypeOpen] = useState(false)
    const [isSelectorModeOpen, setisSelectorModeOpen] = useState(false)

    const [activity, setActivity] = useState({
        TITLE: '',
        ADMIN: userID,
        TYPE: 'Run',
        MODE:'',
        DISTANCE: '',
        CLIMB: '',
        START_TIME: null,
        DURATION: null,
        START_POINT: '',
        CITY: '',
        IMAGE: null,
    });

    const handleAddTraining = async () => {
      // activity.START_TIME = startTime.toString().slice(0, 19).replace('T', ' ');
      // activity.DURATION = duration.toTimeString;
      
      // console.log(activity);

      if (!isNaN(activity.DISTANCE) && !isNaN(activity.CLIMB) && activity.TITLE.length > 0 && activity.START_POINT !== '' && activity.CITY !== '') {
        
        if(activity.IMAGE == null) activity.IMAGE = "https://tivity.fr:4000/images/trainings/default.jpg";

        try {
        const response = await fetch('https://tivity.fr:4000/api/addTraining', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(activity),
        });

        if (response.ok) {
          window.location.reload();
        } else {
          console.log("error");
        }
        } catch (error) {
          console.error('Error:', error);
        }
      }

      onClose();
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      
      if (name === 'IMAGE') {
        setActivity({ ...activity, [name]: e.target.files[0] });
      } else {
        setActivity({ ...activity, [name]: value });
      }
    };

    const handleAddImageClick = () => {
      trainingImageInputRef.current.click();
    };

    const handleDateChange = (date) => {
      console.log(date)
      startTime.set('day', date.$H);
      startTime.set('month', date.$M);
      startTime.set('year', date.$y);

    };
  
    const handleHourChange = (time) => {
      console.log(time);
      startTime.locale()
      startTime.set('hour', time.$H)
      startTime.set('minute', time.$m);
    };
  
    const handleEstimatedTimeChange = (time) => {
      setDuration(time);
    };

    return (
        <Dialog open fullWidth maxWidth="xs">
          <DialogTitle>Ajouter une activité</DialogTitle>
          <DialogContent>
            <Box mt={1} mb={1}>
              <TextField
                label="Titre"
                name="TITLE"
                value={activity.TITLE}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </Box>
            <Box display={"flex"} mb={1}>
              <Box mr={1} sx={{ width: '50%' }}>
                <TextField
                  name="DISTANCE"
                  label="Distance"
                  type="number"
                  value={activity.DISTANCE}
                  onChange={handleInputChange}
                  fullWidth
                  required
                  InputProps={{
                    endAdornment: <InputAdornment position="end">km</InputAdornment>,
                  }}
                />
              </Box>
              <Box sx={{ width: '50%' }}>
                <TextField
                  name="CLIMB"
                  label="Dénivelé"
                  type="number"
                  value={activity.CLIMB}
                  onChange={handleInputChange}
                  fullWidth
                  required
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                  }}
                />
              </Box>
            </Box>
            <Box display={'flex'} mb={1}>
              <Box mr={1} sx={{ width: '50%' }}>
                <FormControl required fullWidth>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    label="Type"
                    name='TYPE'
                    value={activity.TYPE}
                    onChange={handleInputChange} 
                    fullWidth
                    open={isSelectorTypeOpen}
                    onClick={() => setisSelectorTypeOpen(!isSelectorTypeOpen)}

                  >
                    <MenuItem key={1} value="Run">Run</MenuItem>
                    <MenuItem key={2} value="CO">CO</MenuItem>
                    <MenuItem key={3} value="Marche nordique">Marche nordique</MenuItem>
                    <MenuItem key={4} value="Velo">Vélo</MenuItem>
                    <MenuItem key={5} value="Natation">Natation</MenuItem>
                    <MenuItem key={6} value="Triathlon">Triathlon</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ width: '50%' }}>
                <FormControl required fullWidth>
                    <InputLabel id="demo-simple-select-label">Mode</InputLabel>

                    <Select
                      labelId="demo-simple-select-label"
                      label="Type"
                      name='MODE'
                      value={activity.MODE}
                      onChange={handleInputChange} 
                      fullWidth
                      open={isSelectorModeOpen}
                      onClick={() => setisSelectorModeOpen(!isSelectorModeOpen)}
                       
                    >
                      <MenuItem key={3} value="blablarun">Blablarun</MenuItem>
                      <MenuItem key={4} value="avec pause">Avec pause</MenuItem>
                      <MenuItem key={5} value="on fonce">On fonce</MenuItem>
                    </Select>
                  </FormControl>
              </Box>
            </Box>
          <Box mb={1}  >
            <TextField
              name="START_POINT"
              label="Point de départ"
              required
              value={activity.START_POINT}
              onChange={handleInputChange}
              fullWidth
            />
          </Box>
          <Box mb={1}>
            <TextField
              name="CITY"
              label="Ville de départ"
              required
              value={activity.CITY}
              onChange={handleInputChange}
              fullWidth
            />
          </Box>
          <Box display="flex" mb={1} >
            <Box mr={1} >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker 
                    label="Date"
                    format="DD-MM-YYYY"
                    onChange={(date) => {handleDateChange(date)}}
                    required
                  />
              </LocalizationProvider>
            </Box>
            <Box display="flex" sx={{ width: '90%' }}>
              <Box mr={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker 
                      ampm={false}
                      onChange={(time) => {handleHourChange(time)}}
                      label="Heure"
                      required
                    />
                </LocalizationProvider>
              </Box>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker 
                        ampm={false}
                        value={duration}
                        onChange={(e) => {handleEstimatedTimeChange(e)}}
                        label="Durée"
                        name="DURATION"
                        fullWidth
                      />
                  </LocalizationProvider>
                </Box>
              </Box>
          </Box>
          <Box mb={1}>
            <Button variant="contained" color="primary" onClick={handleAddImageClick}>
              Ajouter une image
            </Button>
          </Box>
          <input
            type="file"
            accept="image/*"
            name="IMAGE"
            style={{ display: 'none' }}
            ref={trainingImageInputRef}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleAddTraining} color="primary">
            Ajouter
          </Button>
        </DialogActions>
        
      </Dialog>
    );
}

export default CreateActivity;