import React, {useState, useEffect} from 'react';
import { useGesture } from 'react-use-gesture';

import Grid from '@mui/material/Grid';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MovingIcon from '@mui/icons-material/Moving';
import PlaceIcon from '@mui/icons-material/Place';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import StraightenIcon from '@mui/icons-material/Straighten';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';

import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CardActions from '@mui/material/CardActions';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

import Card from '@mui/material/Card'; 
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import { CardMedia } from '@mui/material';
import Typography from '@mui/material/Typography';

import { useAuth } from '../provider/authProvider';

import CreateActivity from './createActivity';




const actions = [
  { icon: <EmojiEventsIcon />, name: 'Course' },
  { icon: <AddLocationAltIcon />, name: 'Sortie' },
];

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

function Activities(){
  const [open, setOpen] = useState(false);
  const [activities, setActivities] = useState([]);
  const [expanded, setExpanded] = useState(Array(activities.length).fill(false));
  const [isAddTraningVisible, setAddTrainingVisibility] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [ID, setID] = useState(-1);
  const [role, setRole] = useState();
  const  {token} = useAuth();

  useEffect(() => {
    if(token){
      const decodentToken = JSON.parse(atob(token.split('.')[1]));
      setID(decodentToken.id);
      setRole(decodentToken.role);
    }
  }, [token]);

  useEffect(() => {
    fetchActivities(ID); 
  } , [ID]);


  async function fetchActivities(ID) {
    if(ID !== -1){
      try {
        const response = await fetch(`https://tivity.fr:4000/api/getTraining/${ID}`, { method: 'POST' });
        if (!response.ok) {
          throw new Error('La requête a échoué');
        }

        const data = await response.json();

        function formatDateString(dateString) {
          const date = new Date(dateString);
          const day = date.getUTCDate();
          const month = date.getUTCMonth() + 1; 
          const year = date.getUTCFullYear();
          return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
        }
  
        const modifiedData = data.message.map((element) => {
          return {
            ...element,
            DATE: formatDateString(element.DATE), // Assuming the date field is named "date"
            HOUR: element.HOUR,
          };
        });
        
        setActivities(modifiedData); // Mettez à jour le state avec les données récupérées
      } catch (error) {
        console.error('Erreur lors de la récupération des activités :', error);
      }
    }

  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const handleExpandClick = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  const handleInscription = async (ID, index) => {
    console.log("je suis la " + ID + ' ' + index);
    try {
      const response = await fetch(`https://tivity.fr:4000/api/subscribeTraining/${ID}/${index}`, {
        method: 'GET'
      });

      if (!response.ok) {
        throw new Error('La requête a échoué');
      }

      const data = await response.json().message;
      setOpenAlert(true);

    } catch (error) {
      console.error('Erreur lors de la récupération des activités :', error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleActionClick = (actionName) => {

    switch (actionName) {
      case 'Course':
        break;
      case 'Sortie':  
        setAddTrainingVisibility(true);
        break;
      default:
        break;
    }

    handleClose();
  };

  const handleReload = () => {
    window.location.reload(); // Reload the page
  };

  const bind = useGesture({
    onDrag: ({ offset: [_, offsetY] }) => {
      if (offsetY > 100) {
        handleReload();
      }
    },
  });

  return (
    <div style={{marginTop:{md: 200, xs:'0px'}, marginBottom:170}} {...bind()}>
      <Grid container spacing={2}>
        {activities.map((item) => (
          <Grid item key={item.ID} xs={12} sm={6} md={4} lg={4}>
            <Card elevation={3} sx={{margin:1}}>
              <CardMedia
                  component="img"
                  height="194"
                  image={item.IMAGE} 
                  alt="Training"
                />

                <Grid container spacing={1} sx={{justifyContent:"flex-end"}} paddingRight={1} paddingTop={1}>
                  <Grid item>
                    <Chip icon={<StraightenIcon/>} label={item.DISTANCE + " km"} />
                  </Grid>
                  <Grid item>
                    <Chip icon={<MovingIcon/>} label={item.CLIMB + " m"} />
                  </Grid>
                  <Grid item>
                    <Chip icon={<Diversity2Icon/>} label={item.TYPE} />
                  </Grid>
                  <Grid item xs={12}> {/* This will make the "City" and "Hour" stack on a new line */}
                    <Grid container spacing={1}  sx={{justifyContent:"flex-end"}}>

                      <Grid item>
                        <Chip icon={<AccessTimeIcon />} label={item.HOUR} />
                      </Grid>
                      <Grid item>
                        <Chip icon={<EventIcon />} label={item.DATE} />
                      </Grid>                      
                      <Grid item>
                        <Chip icon={<PlaceIcon />} label={item.CITY + " | " + item.START_POINT} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
            
                <CardContent >
                  <Typography variant="h5" style={{ textAlign: 'left' }}>{item.TITLE}</Typography>
                  <Typography variant='subtitle2' style={{ textAlign: 'left' }}>by: {item.FIRSTNAME + " " + item.NAME}</Typography>
                </CardContent>

                <CardActions disableSpacing>
                  <Button variant='contained' size="small" onClick={() => handleInscription(ID, item.ID)}>S'inscrire</Button>
                
                  <ExpandMore
                    expand={expanded[item.ID]}
                    onClick={() => handleExpandClick(item.ID)}
                    aria-expanded={expanded[item.ID]}
                    aria-label="Participants"
                  >
                    <PersonIcon />
                  </ExpandMore>
                </CardActions>

                <Collapse in={expanded[item.ID]} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography variant="body2">Autheur: {item.FIRSTNAME + " " + item.NAME}</Typography>
                    <Typography variant="body2">Point de départ: {item.START_POINT}</Typography>
                    <Typography variant="body2">
                      Date: {new Date(item.DATE).toLocaleDateString()}
                    </Typography>
                  </CardContent>
                </Collapse>
              </Card>
          </Grid>
        ))}
      </Grid>

      <Snackbar open={openAlert} autoHideDuration={1500} onClose={handleCloseAlert} TransitionComponent={SlideTransition} anchorOrigin={{ vertical:'top', horizontal:'right' }}>
        <Alert onClose={handleCloseAlert} severity="success">
        </Alert>
      </Snackbar>

      {isAddTraningVisible && <CreateActivity userID={ID} onClose={() => {setAddTrainingVisibility(false)}}/> }
       
      {role === "ADMIN" || role === "ANIMATOR" ? 
        <SpeedDial
          ariaLabel="Edit action"
          sx={{ position: 'fixed', bottom: 100, right: 20 }}
          icon={<SpeedDialIcon />}
          FabProps={{
            sx: {
              bgcolor: 'secondary.main',
              '&:hover': {
                bgcolor: 'secondary.main',
              }
            }
          }}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              onClick={() => handleActionClick(action.name)}
            />
          ))}
        </SpeedDial> : <></>
      }
    </div>
  );
}
export default Activities;
