import React, { useEffect, useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import RunCircleIcon from '@mui/icons-material/RunCircle';
import HistoryIcon from '@mui/icons-material/History';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import logo from '../assets/images/logo-white.png'

import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';


import { useAuth } from '../provider/authProvider';

const pages = ['Sorties', 'Courses', 'Evenements', 'Historique'];
const pagesMobile = [...pages, 'Mon profil', 'Deconnexion'];
const settings = ['Mon profil', 'Deconnexion'];

// TODO declare this function in an other file 
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

const StyledIconButton = styled(IconButton)({
  position: 'relative',
  top: '-30px', // Décalage vers le haut
});

function ActivitiesBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [value, setValue] = useState('letsrun');
  const isMdScreen = useMediaQuery('(min-width:600px)');

  const [NAME, setNAME] = useState('');
  const [FIRSTNAME, setFIRSTNAME] = useState('');
  const [role, setRole] = useState('');

  const {token} = useAuth();
  const clubID = Cookies.get('club_id');

  const [badgeCounter, setBadgeCounter] = useState(0);

  useEffect(() => {
    if (token) {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      setNAME(decodedToken.name);
      setFIRSTNAME(decodedToken.firstname);
      setRole(decodedToken.role);
      // Use NAME and FIRSTNAME here
    }

    async function fetchData() {
      try {
        const response = await fetch(`https://tivity.fr:4000/api/getNbAdminRequest/${clubID}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();

        setBadgeCounter(data.message[0].total_users);

      } catch (error) {
        console.error('Error:', error);
      }
    }

    fetchData();
  }, [token]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenNavMenu = () => {
    setDrawerOpen(true);
  };

  const handleCloseNavMenu = () => {
    setDrawerOpen(false);
  };

  const handleCloseUserMenu = (event, setting) => {
    setAnchorElUser(null);
    if (setting === 'Profile') {
      // Handle the "Profile" action here
      console.log('Profile clicked');
    } else if (setting === 'Logout') {
      localStorage.removeItem('token');
      navigate("/")
    }
  };

  const handlePageClick = (page) => {
    if(page === "Sorties")
      navigate("/activities");

  }; 

  const handleUserRequest = () => {
    navigate("/administration", { state: { selectedTab: 1 } });
  };

  const handleReachMemberList = () => {
    navigate("/administration", { state: { selectedTab: 0 } });
  };

  return (
      <div >
        <AppBar position={isMdScreen ? "sticky" : "static"} color="primary">
          <Container maxWidth="xl" >
            <Toolbar disableGutters>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
              </Box>

              <Box sx={{display: 'flex', alignItems: 'center', flex: { xs: 2, md: 0 } }}>
                <Link href="/">
                  <Box
                    component="img"
                    sx={{ height: 54, margin: '0 auto' }} // Center the logo
                    alt="Logo"
                    src={logo}
                  />
                </Link>
              </Box>

                <Drawer
                  anchor="left"
                  open={drawerOpen}
                  onClose={handleCloseNavMenu}
                  sx={{width:600}}
                >
                <Box sx={{ width: 300 }}>
                  <Grid container spacing={2} direction="column" alignItems="center">
                    <Grid item sx={{ pt: 10 }}> {/* Add top padding to the avatar */}
                      {NAME && FIRSTNAME ? (
                        <Avatar
                          sx={{ width: 100, height: 100, bgcolor: stringToColor(NAME), marginTop:'16px' }}
                          children={`${FIRSTNAME[0]}${NAME[0]}`}
                        />
                      ) : (
                        <Avatar>U</Avatar>
                      )}
                    </Grid>
                    <Grid item>
                      {NAME && FIRSTNAME ? (
                        <Typography variant="h6" textAlign="center">
                          {FIRSTNAME} {NAME}
                        </Typography>
                      ) : (
                        <Typography variant="h6" textAlign="center">
                          User
                        </Typography>
                      )}
                    </Grid>

                    {role === 'ADMIN' && (
                    <Grid item>
                      <IconButton size="large"  onClick={handleUserRequest}>
                        <Badge badgeContent={badgeCounter} color="error">
                          <MailIcon color='icon.dark' fontSize='large'/>
                        </Badge>
                      </IconButton>

                      <IconButton size="large">
                        <SupervisorAccountIcon color='icon.dark' fontSize='large'/>
                      </IconButton>
                    </Grid>
                  )}
                    
                  </Grid>



                  <Divider sx={{ mt: 2, mb: 1 }} /> 
                  
                  <List>
                    {pagesMobile.map((page) => (
                      <ListItem key={page} onClick={handleCloseNavMenu}>
                          <Typography textAlign="center">{page}</Typography>
                      </ListItem>
                    ))}
                  </List>
                </Box>
                </Drawer>

                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  {pages.map((page) => {
                    if (page !== "Paramètres") {
                      return (
                        <Button
                          key={page}
                          onClick={() => {
                            handleCloseNavMenu();
                            handlePageClick(page);
                          }}
                          sx={{ my: 2, color: 'white', display: 'block', paddingRight:1 }}
                        >
                          {page}
                        </Button>
                      );
                    }
                    return null; // Ne rien rendre si la page est "Paramètres"
                  })}
                </Box>
                <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                {role === 'ADMIN' && (
                  <>
                    <IconButton size="large" onClick={handleUserRequest}>
                      <Badge badgeContent={badgeCounter} color="error">
                        <MailIcon color='icon' />
                      </Badge>
                    </IconButton>

                    <IconButton size="large" onClick={handleReachMemberList}>
                      <SupervisorAccountIcon color='icon'/>
                    </IconButton>
                  </>
                )}

                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    {NAME && FIRSTNAME ? (
                      <Avatar sx={{ bgcolor: stringToColor(NAME) }} children={`${FIRSTNAME[0]}${NAME[0]}`} />
                    ) : (
                      <Avatar>U</Avatar>
                    )}
                  </IconButton>
                </Tooltip>

                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={(event) => handleCloseUserMenu(event, setting)}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        
        <BottomNavigation sx={{position: 'fixed', bottom: 0, left: 0, right: 0, height:95, display: { xs: 'flex', md: 'none' },zIndex: 1}} value={value} onChange={handleChange} >
          <BottomNavigationAction
            label="Historique"
            value="historique"
            icon={<HistoryIcon sx={{ fontSize: 30 }} />}
          />
          <BottomNavigationAction
            label="Let's run"
            value="letsrun"
            icon={<RunCircleIcon sx={{ fontSize: 30 }}/>}
          />
          <BottomNavigationAction
            label="Courses"
            value="race"
            icon={<EmojiEventsIcon sx={{ fontSize: 30 }}/>}
          />
        </BottomNavigation>
      </div>
  );
}
export default ActivitiesBar; 
