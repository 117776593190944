import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';
import { visuallyHidden } from '@mui/utils';
import Cookies from 'js-cookie';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'NAME',
    numeric: false,
    disablePadding: true,
    label: 'Nom',
  },
  {
    id: 'FIRSTNAME',
    numeric: false,
    disablePadding: false,
    label: 'Prénom',
  },
  {
    id: 'EMAIL',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'DATE',
    numeric: false,
    disablePadding: false,
    label: 'Date de la demande',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={headCell.disablePadding ? 'normal' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function UserRequest({admin}) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('NAME');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [requests, setRequests] = React.useState([]);
  const clubID = Cookies.get('club_id');


  async function fetchUserRequest() {
    try {
        const response = await fetch(`https://tivity.fr:4000/api/getUserRequests/${clubID}`);
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setRequests(data.message);

    } catch (error) {
        console.error('Error:', error);
    }
  }

  React.useEffect(() => {
    fetchUserRequest();
  },);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    // const isAcceptButtonClicked = event.target.closest('IconButton[data-action="accept"]');
    // if(isAcceptButtonClicked)
    //   handleAcceptUser(id, admin);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAcceptUser = async (user_id, admin_id) => {
    try {
      const response = await fetch(`https://tivity.fr:4000/api/acceptUser/${user_id}/${admin_id}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      fetchUserRequest();
      
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const handleRejectUser = async (user_id) => {
    try {
      const response = await fetch(`https://tivity.fr:4000/api/rejectUser/${user_id}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      fetchUserRequest();
      
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requests.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(requests, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, requests],
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={requests.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
              const isItemSelected = isSelected(row.ID);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  key={row.ID}
                  hover
                  onClick={(event) => handleClick(event, row.ID)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  selected={isItemSelected}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell component="th" id={labelId} scope="row" padding="normal" align='center'>
                    {row.NAME}
                  </TableCell>
                  <TableCell align="center">{row.FIRSTNAME}</TableCell>
                  <TableCell align="center">{row.EMAIL}</TableCell>
                  <TableCell align="center">
                    {new Date(row.DATE).toLocaleDateString('fr-FR')}
                  </TableCell>

                  <TableCell align='center' component="th" scope="row">
                    <IconButton onClick={() => handleAcceptUser(row.ID, admin)}>
                      <DoneIcon color='success'/>
                    </IconButton>
                  </TableCell>

                  <TableCell align='center' component="th" scope="row">
                    <IconButton onClick={() => handleRejectUser(row.ID)}>
                      <BlockIcon color='error' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={requests.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
