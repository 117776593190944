import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import UserManagement from './UserManagement';
import UserRequest from './UserRequests'

import Cookies from 'js-cookie';
import { useAuth } from '../provider/authProvider';

import { useLocation } from 'react-router-dom';

function Administration(){
    const [value, setValue] = useState(0);
    const clubID = Cookies.get('club_id');
    const [adminID, setAdminID] = useState();
    const location = useLocation();

    const {token} = useAuth();

    useEffect(() => {
      if (token) {
          const decodedToken = JSON.parse(atob(token.split('.')[1]));
          setAdminID(decodedToken.id);
      }

      const selectedTabFromState = location.state?.selectedTab;

      // If selectedTab is present, update the tab value
      if (selectedTabFromState !== undefined) {
        handleChange(null, selectedTabFromState);
      }
    }, [token,location.state?.selectedTab]);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
      <Box
        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab label="Membres" {...a11yProps(0)} />
          <Tab label="Demandes" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <UserManagement/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <UserRequest admin={adminID} />
        </TabPanel>
      </Box>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }


export default Administration;