import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button'; 
import Link from '@mui/material/Link';

import RDS_Visuel from '../assets/images/RDS_2024_Visuel.jpg'

function Race() {
    const [isMobile, setIsMobile] = React.useState(false);

    const checkIsMobile = () => {
        setIsMobile(window.innerWidth <= 600);
    }

    React.useEffect(() => {
        checkIsMobile();

        window.addEventListener('resize', checkIsMobile);

        return () =>{
            window.removeEventListener('resize', checkIsMobile);
        }
    }, []);

    return (
        <Box sx={{ paddingTop: '10px' }}>
            <Grid container spacing={0.5} direction={isMobile ? "column" : "row"}>
                <Grid item xs={6}>
                    <div style={{ padding: '10px'}}>
                        <Paper elevation={3}
                            sx={{
                                backgroundColor: (theme) =>
                                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                            }}>
                            <img
                                src={RDS_Visuel}
                                alt="RDS_Visuel"
                                style={{ width: '100%', height: '100%' }}
                            />
                        </Paper>
                    </div>    
                </Grid>
                <Grid item xs={6}>
                    <div style={{ padding: '10px'}}>
                    <Paper elevation={3}
                    sx={{
                        backgroundColor: (theme) =>
                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                        textAlign:'start',
                        position: 'relative', 
                        
                    }}
                    >
                    <div class="description_rds" style={{ padding: '16px'}}>
                        La Ronde du Salbert est une épreuve nature disputée sur les nombreux sentiers du Salbert.<br/><br/>
                        Pour cette 21ème édition, Trail Belfort Marathon reconduit <strong>Les 10h du Salbert</strong> et   
                        <strong>Les 6h du Salbert</strong> (2 ou 3 personnes) à courir en solo ou en relais. Le but est de 
                        réaliser un maximum de boucles dans le temps imparti !<br/><br/>
                        Le samedi, vous pouvez également participer à <strong>La Route</strong>, montée par la route en 
                        contre-la-montre.<br/>
                        Nouveauté : nous vous proposons cette année une marche non chronométrée : <strong>La Marche Nordique / Rando</strong>.<br/><br/>
                        Le dimanche, le <strong>Petit Salbert</strong> et le <strong>Grand Salbert</strong> font leur retour.<br/><br/>
                        Il y en a pour tous les goûts !<br/>
                        <br/>

                        <span >Les épreuves :</span>
                        <ul>
                            <li>le samedi :</li>
                                <ul>
                                    <li><strong>Les 10h du Salbert</strong> (départ 8h) et <strong>Les 6h du Salbert</strong> (départ 12h) sur un circuit 
                                    dans le Salbert (5,8km D+ 250m, <a href="https://www.openrunner.com/route-details/16095710" target="_blank">parcours</a>), en solo ou en relais</li>
                                    <li><strong>La Route</strong>, montée du Salbert par la route en contre-la-montre, 
                                    de 4,6 km avec un D+ de 220m : départ à 15h</li>
                                    <li><strong>Marche Nordique / Rando</strong>, sur le parcours du Petit Salbert du lendemain (10 km, D+ 435m) : départ à 14h</li>
                                </ul>
                            <li>le dimanche :</li>
                                <ul>
                                    <li><strong>La Costaud - Grand Salbert</strong>, course de 19 km avec un D+ de 850m : départ à 9h15</li>
                                    <li><strong>La Rapide - Petit Salbert</strong>, course de 10 km avec un D+ de 365m : départ à 10h00</li>
                                </ul>
                        </ul>
                        <br/>
                    </div>
                    
                    <Link href="https://sporkrono-inscription.fr/ronde-salbert-2024/select_competition" target="_blank" rel="noopener noreferrer">
                        <Button
                            fullWidth  // Make the button span the full width of the Paper
                            variant="contained"
                            color="primary"
                            sx={{
                            position: 'absolute', // Position the button absolutely at the bottom
                            bottom: 0, // Align the button at the bottom
                            }}
                        >
                            S'inscrire ! 
                        </Button>
                    </Link>
                </Paper>
                </div>
                </Grid>
            </Grid>
        </Box>
      );
}


export default Race;